<template lang="pug">
.page
  .menuList
    el-card.menuItem(header="商场楼层导览")
      el-form
        el-form-item
          el-switch(active-text="显示" inactive-text="隐藏" :active-value="1" :inactive-value="0" v-model="form.switchGuide" @change="onSwitchGuideChange")
    el-card.menuItem(header="停车指南")
      el-form
        el-form-item 
          el-switch(active-text="显示" inactive-text="隐藏" :active-value="1" :inactive-value="0" v-model="form.switchParkingGuide" @change="onSwitchParkingGuideChange")
        el-form-item
          el-button(type="primary" @click="router.push('/info/parking')") 停车指南设置
    el-card.menuItem(header="轮播图")
      el-form
        el-form-item
          el-button(type="primary" @click="router.push('/info/banner')") 商场轮播图设置
</template>

<script setup>
import { inject, onMounted, computed, ref } from 'vue'

import { getMockData, updateMockData } from '@/provider/mock'
import { currentMarketId } from '@/provider/account'
import comEditor from '@/component/editor/index.vue'

const { fetch, message, router } = inject('global')

const form = ref({
  switchGuide: 1,
  switchParkingGuide: 1
})
const raw = ref({})

function onSwitchGuideChange(val) {
  fetch
    .post(
      `/boom-center-product-service/businessAdmin/commercialPlazaAssistant/facilitiesEdit/${currentMarketId.value}`,
      {
        commercialPlazaId: currentMarketId.value,
        switchGuide: val
      }
    )
    .then(res => {
      message.success('保存成功')
    })
    .catch(err => {
      message.error(err?.msg || err)
    })
}
function onSwitchParkingGuideChange(val) {
  fetch
    .post(
      `/boom-center-product-service/businessAdmin/commercialPlazaAssistant/facilitiesEdit/${currentMarketId.value}`,
      {
        commercialPlazaId: currentMarketId.value,
        switchParkingGuide: val
      }
    )
    .then(res => {
      message.success('保存成功')
    })
    .catch(err => {
      message.error(err?.msg || err)
    })
}

function getData() {
  fetch
    .get(`/boom-center-product-service/sysAdmin/commercialPlaza/facilities/${currentMarketId.value}`)
    .then(res => {
      raw.value = res
      form.value.switchGuide = res.switchGuide
      form.value.switchParkingGuide = res.switchParkingGuide
    })
    .catch(err => {
      message.error(err?.msg || err)
    })
}

onMounted(() => {
  getData()
})
</script>

<style lang="less" scoped>
.menuList {
  display: flex;
  flex-wrap: wrap;
  .menuItem {
    flex-grow: 1;
    flex-basis: 400px;
  }
}
</style>
