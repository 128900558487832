import fetch from '@/plugin/axios'

export const getMockData = configSet => {
  return fetch
    .get('/boom-center-config-service/app/configuration/findConfigByParamAsJson', {
      params: {
        configSet: configSet,
        module: 'mock'
      }
    })
    .then(res => {
      if (res?.content) {
        return res.content
      }
      return Promise.reject('mock data null')
    })
}
export const updateMockData = (configSet, payload) => {
  return fetch.post('/boom-center-config-service/sysAdmin/configuration/saveOrUpdate', {
    content: payload,
    configSet: configSet,
    module: 'mock'
  })
}
